import React from "react";
import css from "./modal.module.css";

const Modal = ({ isModal, setIsModal, page }) => {
  return (
    <div
      className={css.container}
      onClick={() => {
        setIsModal(false);
      }}
    >
      <div className={css.contentWrapper}>
        <div
          className={css.cancel}
          onClick={() => {
            setIsModal(false);
          }}
        >
          <img src="../img/wetransfer_01-pictures_2023-09-17_0843/icon/Close.png" />
        </div>
        {page === "export" ? (
          <>
            <div className={css.top}>
              <h1>Экспорт</h1>
              <div className={css.redline} />
            </div>
            <div style={{ overflow: "scroll" }}>
              <div className={css.bottom}>
                <div className={css.grid} style={{}}>
                  <p>
                    Газрын тос, геологи, эрдэс баялгийн чиглэлээр хийгдсэн
                    судалгааны материал /зураг, өрмийн дээж, чулуулгийн дээж,
                    геологийн тайлан, дискэнд буулгасан судалгааны бичиглэл г.м/
                  </p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>Үйлдвэр, худалдааны яамны зөвшөөрөл</li>
                    <li>Үнийн нэхэмжлэх</li>
                    <li>Баглаа боодлын жагсаалт</li>
                    <li>Тээврийн бичиг баримт</li>
                  </ul>
                </div>
                <div className={css.grid}>
                  <p>
                    Батлан хамгаалах асуудалтай хамааралтай судалгаа шинжилгээ,
                    газар нутгийн загвар дүрс, зураг зүйн материалыг
                  </p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>Батлан хамгаалах яамны зөвшөөрөл</li>
                    <li>Үнийн нэхэмжлэх</li>
                    <li>Баглаа боодлын жагсаалт</li>
                    <li>Тээврийн бичиг баримт</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{ height: "250px", borderBottom: "1px solid #CFD8DC" }}
                >
                  <p>
                    Амьтан ургамал, тэдгээрийн гаралтай түүхий эд, тэдгээрийн
                    зүйл, судалгаа, шинжилгээний сорьц дээж
                  </p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    height: "250px",
                    borderLeft: "1px solid #CFD8DC",
                    borderBottom: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>
                      Байгаль орчны яамны тодорхойлолт Жич: Ургамлын гаралтай
                      түүхий эдийн сорьц дээжид `Хүн Байгаль
                    </li>
                    <li>Орчин Консорциум`-ын тодорхойлолт</li>
                    <li>Үнийн нэхэмжлэх</li>
                    <li>Баглаа боодлын жагсаалт</li>
                    <li>Тээврийн бичиг баримт</li>
                    <li>Баглаа боодлын жагсаалт</li>
                    <li>Мал, эмнэлэг, хорио цээрийн сертификат</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{ height: "250px", borderBottom: "1px solid #CFD8DC" }}
                >
                  <p>
                    Батлан хамгаалах асуудалтай хамааралтай судалгаа шинжилгээ,
                    газар нутгийн загвар дүрс, зураг зүйн материалыг
                  </p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    height: "250px",
                    borderLeft: "1px solid #CFD8DC",
                    borderBottom: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>
                      Байгаль орчны яамны тодорхойлолт Жич: Ургамлын гаралтай
                      түүхий эдийн сорьц дээжид `Хүн Байгаль
                    </li>
                    <li>Орчин Консорциум`-ын тодорхойлолт</li>
                    <li>Үнийн нэхэмжлэх</li>
                    <li>Баглаа боодлын жагсаалт</li>
                    <li>Тээврийн бичиг баримт</li>
                    <li>Баглаа боодлын жагсаалт</li>
                    <li>Мал, эмнэлэг, хорио цээрийн сертификат</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{ height: "250px", borderBottom: "1px solid #CFD8DC" }}
                >
                  <p>
                    Зэрлэг амьтан ба ургамлын ховордсон зүйлийг олон улсын
                    хэмжээнд худалдаалах тухай конвенци /CITES/-ийн хавсралтын
                    амьтан, ургамал
                  </p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    height: "250px",
                    borderLeft: "1px solid #CFD8DC",
                    borderBottom: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>CITES-ийн зөвшөөрөл</li>
                    <li>Үнийн нэхэмжлэх</li>
                    <li>Баглаа боодлын жагсаалт</li>
                    <li>Тээврийн бичиг баримт</li>
                    <li>Мал, эмнэлэг, хорио цээрийн сертификат</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    height: "250px",
                    borderBottom: "1px solid #CFD8DC",
                  }}
                >
                  <p>
                    Түүх, соёлын хосгүй үнэт, үнэт дурсгалт зүйлс, оюуны болон
                    урлаг, угсаатны зүйн бүтээл
                  </p>
                  <ul>
                    <li>Хосгүй үнэт</li>
                    <li>Үнэт дурсгалын зүйл</li>
                    <li>Ердийн дурсгалт зүйл</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    height: "250px",
                    borderLeft: "1px solid #CFD8DC",
                    borderBottom: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>БСШУЯ-ны лиценз /сайдын гарын үсэгтэй/</li>
                    <li>
                      БСШУЯ-ны лиценз /соёл урлаг, бодлого зохицуулалтын газрын
                      даргын гарын үсэгтэй/
                    </li>
                    <li>Соёлын өвийн төвийн гэрчилгээ</li>
                    <li>Үнийн нэхэмжлэх</li>
                    <li>Баглаа боодлын жагсаалт</li>
                    <li>Тээврийн бичиг баримт</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderBottom: "1px solid #CFD8DC",
                  }}
                >
                  <p>
                    Гадаадын анчдаар тусгай төлбөртэйгөөр агнуулсан ан, ангийн
                    олзвор
                  </p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    borderBottom: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>БСШУЯ-ны зөвшөөрөл</li>
                    <li>Үнийн нэхэмжлэх</li>
                    <li>Баглаа, боодлын тодорхойлолт</li>
                    <li>Тээврийн бичиг баримт</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    borderBottom: "1px solid #CFD8DC",
                  }}
                >
                  <p>Монгол гэр</p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    borderBottom: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>Гарал үүслийн гэрчилгээ</li>
                    <li>Үнийн нэхэмжлэх</li>
                    <li>Баглаа, боодлын тодорхойлолт</li>
                    <li>Тээврийн бичиг баримт</li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={css.top}>
              <h1>Импорт</h1>
              <div className={css.redline} />
            </div>
            <div style={{ overflow: "scroll" }}>
              <div className={css.bottom}>
                <div
                  className={css.grid}
                  style={{
                    height: "200px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p>Буу, сум, галт зэвсэг:</p>
                  <ul>
                    <li>Батлан хамгаалах яамны зөвшөөрөл</li>
                    <li>Үнийн нэхэмжлэх</li>
                    <li>Баглаа боодлын жагсаалт</li>
                    <li>Тээврийн бичиг баримт</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                    height: "200px",
                  }}
                >
                  <ul>
                    <li>
                      ЦЕГ, ТЕГ, БХЯ-ны тодорхойлолт /тендерт шалгарсан ҮХЯамны
                      зөвшөөрөл/
                    </li>
                    <li>ЦЕГ-ын зөвшөөрөл</li>
                    <li>ЦЕГ-ын түр зөвшөөрөл</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p>Цацраг идэвхит бодис, изотоп</p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>Цацрагийн хяналтын албаны тодорхойлолт</li>
                    <li>УМХГ-ын мэдэгдэл</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    height: "200px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p>Химийн:</p>
                  <ul>
                    <li>Онцгой хортой бодис</li>
                    <li>Хортой бага хортой бодис</li>
                    <li>Озон задалдаг бодис, түүнийг агуулдаг тоног </li>
                    <li>Төхөөрөмж</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                    height: "200px",
                  }}
                >
                  <ul>
                    <li>
                      ЦЕГ, ТЕГ, БХЯ-ны тодорхойлолт /тендерт шалгарсан ҮХЯамны
                      зөвшөөрөл/
                    </li>
                    <li>ЦЕГ-ын зөвшөөрөл</li>
                    <li>ЦЕГ-ын түр зөвшөөрөл</li>
                  </ul>
                </div>
                <div className={css.grid}>
                  <p>
                    Хүний ийлдэс, донорын цус, дархлалын хувирмал бэлдмэл, бичил
                    биетний өсгөвөр, эм, эмийн зүйл, оношлуур, урвалж, вакцин,
                    эмнэлгийн багаж төхөөрөмж, хэрэгсэл
                  </p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>ЭМЯ-ны зөвшөөрөл</li>
                    <li>Эм, эмнэлгийн хэрэгсэл импортлох зөвшөөрөл</li>
                    <li>УМХГ-ын мэдэгдэл</li>
                    <li>Тохирлын гэрчилгээ</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p>Үнэт металл, эрдэнэсийн чулуу, алт</p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>
                      Сорьцын улсын хяналтын албаны сорьц тодорхойлсон акт
                    </li>
                    <li>ТЕГ-ын нөөц ашигласны төлбөр хийсэн баримт</li>
                    <li>Гарал үүслийн гэрчилгээ</li>
                  </ul>
                </div>
                <div
                  className={css.grid}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p>Уламжлалт эм</p>
                </div>
                <div
                  className={css.grid}
                  style={{
                    borderLeft: "1px solid #CFD8DC",
                    paddingLeft: "40px",
                  }}
                >
                  <ul>
                    <li>УМХГ-ын мэдэгдэл </li>
                    <li>Чанарын гэрчилгээ</li>
                    <li>Гарал үүслийн гэрчилгээ</li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
