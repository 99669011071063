import React, { useEffect, useState } from "react";
import css from "./hereglegch.module.css";
import Modal from "../modal/modal";

const Hereglegch = () => {
  const [isModal, setIsModal] = useState(false);
  const [isTeever, setIsTeever] = useState(true);
  const [page, setPage] = useState("export");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Add an event listener to update screenWidth when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <div id="Хэрэглэгчийн булан" className={css.container} style={!isTeever && screenWidth < 800 ? {height:"700px"}:{}}>
        <div className={css.backgroundTop}>
          <img
            className={css.img}
            src="https://i.imgur.com/YeBYNm9.png"
            alt="zurag"
          />
        </div>
        <div className={css.backgroundBottom} />
        <div
         className={css.a}
        >
          <div className={css.contentContainer}>
            <h1>Хэрэглэгчийн булан</h1>
            <div className={css.buttons}>
              <button
                style={isTeever ? { borderBottom: "4px solid white" } : {}}
                onClick={() => {
                  setIsTeever(true);
                }}
              >
                Тээвэрлэлтэд анхаарах зүйлс
              </button>
              <button
                style={!isTeever ? { borderBottom: "4px solid white" } : {}}
                onClick={() => {
                  setIsTeever(false);
                }}
              >
                Инкотермс
              </button>
            </div>
            {isTeever ? (
              <div className={css.cards}>
                <div className={css.card}>
                  <div className={css.imgWrapper}>
                    <img src="https://i.imgur.com/t8TTyhr.jpg" alt="img" />
                  </div>
                  <div className={css.cardBottom}>
                    <h3>Экспорт</h3>
                    <p>
                      Газрын тос, геологи, эрдэс баялгийн чиглэлээр хийгдсэн
                      судалгааны материал /зураг, өрмийн дээж, чулуулгийн дээж,
                      геологийн тайлан, дискэнд буулгасан судалгааны бичиглэл
                      г.м/
                    </p>
                    <button
                      onClick={() => {
                        setIsModal(true);
                        setPage("export");
                      }}
                    >
                      Дэлгэрэнгүй үзэх
                      <img src="https://i.imgur.com/E2LBUHm.png" alt="arrow" />
                    </button>
                  </div>
                </div>
                <div className={css.card}>
                  <div className={css.imgWrapper}>
                    <img src="https://i.imgur.com/IJmkeR9.jpg" alt="img" />
                  </div>
                  <div className={css.cardBottom}>
                    <h3>Импорт</h3>
                    <p>
                      Буу, сум, галт зэвсэг:
                      <br /> <strong>·</strong> ААН-ийн импортолсон <br />
                      <strong>·</strong> Монгол улсын иргэний хувийн хэрэглээнд
                      Гадаадын анчдын хэрэгцээний
                    </p>
                    <button
                      onClick={() => {
                        setIsModal(true);
                        setPage("import");
                      }}
                    >
                      Дэлгэрэнгүй үзэх
                      <img src="https://i.imgur.com/E2LBUHm.png" alt="arrow" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.inkotermsContainer}>
                <img src="https://i.imgur.com/RH2pRT4.png" alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
      {isModal ? (
        <Modal isModal={isModal} setIsModal={setIsModal} page={page} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Hereglegch;
