import React from "react";
import css from "./index.module.css";

const About = () => {
  return (
    <div id="Бидний тухай" style={{ backgroundColor: "white", zIndex: "-1" }}>
      <div className={css.container}>
        <div className={css.container2}>
          <div className={css.left}>
            <div className={css.leftTop}>
              <h1>Бидний тухай</h1>
            </div>
            <div className={css.redLine} />
            <div className={css.leftBottom}>
              <p>
                Манай байгууллага нь 2015 олнооснд олон улсын тээвэр зуучлалын
                чиглэлээр байгуулагдаж үйл ажиллагаагаа эхэлсэн 100% дотоодын
                хөрөнгө оруулалттай компани юм.
                <br /> <br />
                Манай компани өндөр мэдлэг, туршлагатай, эрч хүчтэй, чадварлаг
                хамт олноос бүрдсэн бөгөөд 30 гаруй улс орны 500 гаруй
                агентуудтай хамтран ажиллаж улс хоорондын энгийн ачаа болон
                аюултай ачааны чиглэлээр амжилттай үйл ажиллагаа явуулан Монгол
                улсын ачаа тээврийн салбарын хөгжил дэвшлийн төлөө өөрсдийн хувь
                нэмрээ оруулан ажиллаж байна.
              </p>
            </div>
          </div>
          <div className={css.right}>
            <div className={css.rightImg}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src="https://i.imgur.com/c2vXiHa.jpg"
                alt="conversation"
              />
              <div className={css.redFilter}></div>
            </div>
          </div>
        </div>
        <img
          className={css.backgroundImage}
          src="https://i.imgur.com/YeBYNm9.png"
          alt="map"
        />
      </div>
    </div>
  );
};

export default About;
