import React, { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import css from "./carousel.module.css"
import { useNavigate } from 'react-router-dom'

const Carousel = ({children:slides,autoSlide = false, autoSlideInterval = 3000,data}) => {
  const navigate = useNavigate()
  const [curr,setCurr] = useState(0)

  console.log(data)

  const prev = () => {
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1))
  }
  const next = () => {
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1))
  }

  useEffect(()=>{
    if(!autoSlide) return
      const slideInterval = setInterval(next,autoSlideInterval)
      return () => clearInterval(slideInterval)
    
  },[])
  return (
    <div className='overflow-hidden relative'>
      <div className='flex transition-transform ease-out duration-500' style={{transform:`translateX(-${curr * 100}%)`}} >
        {slides}
      </div>
      <div className="absolute inset-0 flex items-center justify-between p-4">
        <button
          onClick={prev}
          className="p-1  text-white hover:text-[#DE5E62]"
        >
          <ChevronLeft size={40} />
        </button>
        <button
          onClick={next}
          className="p-1  text-white hover:text-[#DE5E62]"
        >
        <ChevronRight size={40} />
        </button>
      </div>
      <div className={`absolute left-1/3 -translate-x-1/3 bottom-1/3 translate-y-1/3 sm:bottom-1/2 sm:translate-y-1/2 h-72 flex flex-col gap-4 md:gap-1 ${
            curr === 1 ? 'md:w-[40%]' : 'w-[50%]'
      }`}>
        <span className={`text-3xl md:text-5xl xl:text-7xl font-bold text-white w-full md:h-40 leading-tight`}>
          {data[curr].text}
        </span>
        <button className='bg-white text-xs md:text-base w-32 md:w-60 h-12 md:h-14 font-semibold' onClick={()=> navigate("/offer")}>
          {data[curr].btnText1}
        </button>
      </div>
      
      <div className="absolute bottom-0 right-0 left-0">
        <div className={`hidden lg:flex justify-center  ${css.btnContainer}`}>
          {slides.map((item, i) => (
            <div
              className={`
              transition-all w-52 h-40  px-5 py-0 border-left-[0.5px] hover:bg-opacity-70
              ${curr === i ? "bg-[#DE5E62]" : "bg-black bg-opacity-50"}
            `}
            onClick={()=>setCurr(i)}
            >
              <div className='h-20 relative'>
              <img src={data[i].icon} className='mb-2 absolute bottom-0'/>

              </div>
              <div className='w-28'>

              <span className='text-white w-8 text-base font-semibold leading-4' >{data[i].btnText}</span>
              </div>

            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Carousel