import React from "react";
import css from "./footer.module.css";

const Footer = () => {
  return (
    <div id="Холбоо барих" className={`w-screen`}>
      <div className="relative w-screen bg-black">
  <img
    src="../img/wetransfer_01-pictures_2023-09-17_0843/01 pictures/10.jpg"
    alt=""
    className={css.images}
  />
  <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-11/12 md:w-4/5 h-96 flex flex-col justify-center gap-2 md:gap-9">
    <div className="flex flex-col gap-0 md:gap-6">
      <span className="text-white text-base md:text-5xl font-bold">
        Холбоо барих
      </span>
      <div className="bg-[#DE5E62] h-1 w-20 md:w-52"></div>
    </div>
    <div className="w-full md:w-2/6 flex flex-col gap-2 md:gap-3">
      <div className="flex flex-row items-center gap-3">
        <img src="../img/icons/location.png" alt="" className="w-4 h-4 md:w-6 md:h-6" />
        <span className="text-white text-xs md:text-lg md:leading-7">
          Монгол улс, Улаанбаатар хот, Сүхбаатар дүүрэг, 1-р хороо, Шүрэн
          оффис, 3 давхар
        </span>
      </div>
      <div className="flex flex-row items-center gap-3">
        <img src="../img/icons/call.png" className="w-4 h-4 md:w-6 md:h-6" />
        <span className="text-white text-xs md:text-lg md:leading-7">+976 77779030</span>
      </div>
      <div className="flex flex-row items-center gap-3">
        <img src="../img/icons/sms-tracking.png" className="w-4 h-4 md:w-6 md:h-6" />
        <span className="text-white text-xs md:text-lg md:leading-7">
          sales@skyprologistics.mn
        </span>
      </div>
    </div>
  </div>
</div>

      <div className={`bg-[#263238] h-20 relative px-4 md:px-0 flex md:justify-center `}>
  <div className="w-screen md:w-4/5 h-full py-3 md:py-0 flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
    <div className="flex justify-self-start gap-2 md:gap-4">
      <span className="text-white text-xs md:text-sm font-light">
        Terms & Conditions
      </span>
      <span className="text-white text-xs md:text-sm font-light">|</span>
      <span className="text-white text-xs md:text-sm font-light">
        Privacy Policy
      </span>
      <span className="text-white text-xs md:text-sm font-light">|</span>
      <span className="text-white text-xs md:text-sm font-light">Sitemap</span>
    </div>
    <div className="flex flex-col md:items-end">
      <span className="text-white text-xs md:text-sm font-light">
        COPYRIGHT © 2023
      </span>
      <span className="text-white text-xs md:text-sm font-light">
        SkyPro Logistics LLC. ALL RIGHTS RESERVED
      </span>
    </div>
  </div>
</div>

    </div>
  );
};

export default Footer;
