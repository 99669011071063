import React, { useContext } from 'react' 
import css from "./sideMenu.module.css"
import GlobalHook from '../../hooks/globalctx';

const SideMenu = ({setIsSideMenu}) => {
  const {language,setLanguage} = useContext(GlobalHook);

  const menuItems = ["Нүүр хуудас","Бидний тухай", "Бүтээгдэхүүн үйлчилгээ", "Хэрэглэгчийн булан", "Холбоо барих"]

  function scrollToDivById({ id = "" }) {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div className={css.container}>
      <div className={css.menu}>
      <div
          className={css.cancel}
          onClick={() => {
            setIsSideMenu(false);
          }}
        >
        
          <img style={{width:"80px"}} src={"https://i.imgur.com/UBveVNn.png"} alt="logo" />
          <img src="../img/wetransfer_01-pictures_2023-09-17_0843/icon/Close.png" />
        </div>    
          <div className={css.menuItems}>
            {
              menuItems.map((item)=>
              <div key={item} className={css.menuItem} onClick={()=>{
                scrollToDivById({id:item})
                setIsSideMenu(false)
                }}>
                 {item}
              </div>
              )
            }
            <div  className={css.menuItem} onClick={()=>{
              setLanguage("mn")
              setIsSideMenu(false)
                }}>
                 <img src="https://flagicons.lipis.dev/flags/4x3/mn.svg" alt="mn" onClick={()=>{setLanguage("mn")}}/>
                Mon
              </div>
            <div  className={css.menuItem} onClick={()=>{
              setLanguage("en")
              setIsSideMenu(false)
                }}>
                <img src="https://flagicons.lipis.dev/flags/4x3/us.svg" alt="en" onClick={()=>{setLanguage("en")}}/>
                Eng
              </div>
          </div>
      </div>
    </div>
  )
}

export default SideMenu