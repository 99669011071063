import React, { useContext, useEffect, useState } from "react";
import css from "./layout.module.css";
import { ChevronDown,Menu } from "react-feather";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../components/sideMenu/sideMenu";
import  GlobalHook  from "../../hooks/globalctx";

const Layout = ({isSideMenu, setIsSideMenu}) => {
  const navigate = useNavigate();
  const {language,setLanguage} = useContext(GlobalHook);

  const [scrollY, setScrollY] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [isLanguageDropdown,setIsLanguageDropDown]=useState(false)
  const [logoSrc, setLogoSrc] = useState(
    "https://i.imgur.com/UBveVNn.png"
  );

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (scrollY > 200) {
      setScrolled(true);
      setLogoSrc(
        "https://i.imgur.com/UBveVNn.png"
      );
    } else {
      setLogoSrc(
        "https://i.imgur.com/ITIJRCM.png"
      );
      setScrolled(false);
    }
  }, [scrollY]);

  function scrollToDivById({ id = "" }) {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div
      className={css.container}
      style={
        scrolled
          ? {
              height: "75px",
              backgroundColor: "white",
              color: "black",
              boxShadow: "0px 3px 5px 0px #00000026",
            }
          : {}
      }
    >
      <div className={css.secondContainer}>
        <div
          className={css.logoWrapper}
          onClick={() => {
            navigate("/");
          }}
        >
          <img className={css.logo} src={logoSrc} alt="logo" />
        </div>
        <div
          className={css.navbarRight}
          style={
            scrolled
              ? {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }
              : {}
          }
        >
          <div
            className={css.top}
            style={scrolled ? { display: "none" } : { display: "flex" }}
          >
            <div>
              <img
                style={{ width: "20px", height: "20px" }}
                src="../img/icons/call.png"
                alt="phone"
              />
              <span>+976 77779030</span>
            </div>
            <div onClick={()=>{setIsLanguageDropDown(!isLanguageDropdown)}} className={css.language}>
              { language ==="mn"? "Mon":"Eng"}
              <div className={css.ChevronDown}>
                <ChevronDown />
              </div>
             { isLanguageDropdown && <div className={css.languageDropdown}>
                <img src="https://flagicons.lipis.dev/flags/4x3/mn.svg" alt="mn" onClick={()=>{setLanguage("mn")}}/>
                <img src="https://flagicons.lipis.dev/flags/4x3/us.svg" alt="en" onClick={()=>{setLanguage("en")}}/>
              </div>}
            </div>
            <div>
              <button
                onClick={() => {
                  navigate("/offer");
                }}
              >
                Үнийн санал авах
              </button>
            </div>
          </div>
          <div className={css.bottom} style={{ height: "fit-content" }}>
            <div className={css.menu}>
              <div className={css.menuItemWrapper}>
                <div
                  className={css.menuItem}
                  onClick={() => {
                    scrollToDivById({ id: "Нүүр хуудас" });
                  }}
                >
                  <span>Нүүр хуудас</span>
                </div>
                <div
                  className={css.menuItemBorder}
                  style={scrolled ? { backgroundColor: "black" } : {}}
                ></div>
              </div>
              <div className={css.menuItemWrapper}>
                <div
                  className={css.menuItem}
                  onClick={() => {
                    scrollToDivById({ id: "Бидний тухай" });
                  }}
                >
                  <span>Бидний тухай</span>
                </div>
                <div
                  className={css.menuItemBorder}
                  style={scrolled ? { backgroundColor: "black" } : {}}
                ></div>
              </div>
              <div className={css.menuItemWrapper}>
                <div
                  className={css.menuItem}
                  onClick={() => {
                    scrollToDivById({ id: "Бүтээгдэхүүн үйлчилгээ" });
                  }}
                >
                  <span>Бүтээгдэхүүн үйлчилгээ</span>
                </div>
                <div
                  className={css.menuItemBorder}
                  style={scrolled ? { backgroundColor: "black" } : {}}
                ></div>
              </div>
              <div className={css.menuItemWrapper}>
                <div
                  className={css.menuItem}
                  onClick={() => {
                    scrollToDivById({ id: "Хэрэглэгчийн булан" });
                  }}
                >
                  <span>Хэрэглэгчийн булан</span>
                </div>
                <div
                  className={css.menuItemBorder}
                  style={scrolled ? { backgroundColor: "black" } : {}}
                ></div>
              </div>
              <div className={css.menuItemWrapper}>
                <div
                  className={css.menuItem}
                  onClick={() => {
                    scrollToDivById({ id: "Холбоо барих" });
                  }}
                >
                  <span>Холбоо барих</span>
                </div>
                <div
                  className={css.menuItemBorder}
                  style={scrolled ? { backgroundColor: "black" } : {}}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div onClick={()=>{
          setIsSideMenu((prev)=>!prev)
        }} className={css.mobileMenu}>
          <Menu/>
        </div>
      </div>
    </div>
  );
};

export default Layout;
