import React, { useState, createContext, useEffect } from "react";

const Ctx = createContext();

export const GlobalHook = (props) => {
  const [language, setLanguage] = useState("mn");
  useEffect(()=>{
    console.log(language)
  },[language])
  return (
    <Ctx.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {props.children}
    </Ctx.Provider>
  );
};

export default Ctx;
