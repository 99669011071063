import React from "react";
import css from "./home.module.css";
import About from "../../components/about";
import Naidvartai from "../../components/naidvartai/naidvartai";
import Buteegdehuun from "../../components/buteegdehuun/buteegdehuun";
import Hereglegch from "../../components/hereglegch/hereglegch";
import HeroSection from "../../components/hero/HeroSection";
import Footer from "../../components/footer/Footer";

const Home = () => {
  return (
    <div className={css.container}>
      <HeroSection />
      <About />
      <Naidvartai />
      <Buteegdehuun />
      <Hereglegch />
      <Footer />
      <img
        className={css.backgroundImage}
        src="https://i.imgur.com/3sCDVTs.jpg"
        alt="backgroundImage"
      />
    </div>
  );
};

export default Home;
