import React from "react";
import css from "./herosection.module.css";
import Carousel from "./Carousel";

const HeroSection = () => {
  const slides = [
    {
      img: "https://i.imgur.com/71PVSKd.jpg",
      icon: "./img/icons/Car.png",
      btnText1: "Үнийн санал авах",
      id: 3,
      link: "",
      text: "Найдвартай тээвэрлэлт",      
      btnText: "Авто тээвэр",
    },
    {
      img: "https://i.imgur.com/RhtsegI.jpg",
      icon: "./img/icons/Train.png",
      btnText1: "Үнийн санал авах",
      id: 0,
      link: "",
      text: "Хурдан шуурхай үйлчилгээ",
      btnText: "Төмөр замын тээвэр",
    },
    {
      img: "https://i.imgur.com/4Pen9QQ.jpg",
      icon: "./img/icons/Plane.png",
      btnText1: "Үнийн санал авах",
      id: 1,
      link: "",
      text: "Дэлхийн өнцөг булан бүрээс...",
      btnText: "Агаарын тээвэр",
    },
    {
      img: "https://i.imgur.com/i5c3MbW.jpg",
      icon: "./img/icons/Ship.png",
      btnText1: "Үнийн санал авах",
      id: 2,
      link: "",
      text: "Тээвэр, гаалийн мэргэжлийн зөвөлгөө",
      btnText: "Далайн тээвэр",
    },
    {
      img: "https://i.imgur.com/ytU3Uy2.jpg",
      icon: "./img/icons/Box.png",
      btnText1: "Үнийн санал авах",
      id: 4,
      link: "",
      text: "Мэргэжлийн чадварлаг хамт олон",
      btnText: "Аюултай ачаа тээвэр",
    },
    {
      img: "https://i.imgur.com/ZPV29W8.jpg",
      icon: "./img/icons/Boxes.png",
      btnText1: "Үнийн санал авах",
      id: 4,
      link: "",
      text: "Мэргэжлийн чадварлаг хамт олон",
      btnText: "Түүвэр ачаа тээвэр",
    },
  ];
  return  (
    <div className="max-w-full h-3/4" id="Нүүр хуудас">
      <Carousel data={slides} className={css.carouselContainer}>
        {slides.map((item, idx) => (
          <img
            className={css.images}
            src={item.img}
            key={idx}
            
            alt={idx}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default HeroSection;
