import React, { useContext, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import Layout from "./pages/layout/layout";
import PriceOffer from "./pages/priceOffer/PriceOffer";
import SideMenu from "./components/sideMenu/sideMenu";
import  GlobalHook  from "./hooks/globalctx";

function App() {
  const [isSideMenu, setIsSideMenu] = useState(false);
  const {language,setLanguage} = useContext(GlobalHook);


  return (
    <>
      <BrowserRouter>
        <Layout isSideMenu={isSideMenu} setIsSideMenu={setIsSideMenu} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/offer" element={<PriceOffer />} />
        </Routes>
        {isSideMenu ? (<SideMenu isSideMenu={isSideMenu} setIsSideMenu={setIsSideMenu}/>): null}

      </BrowserRouter>
    </>
  );
}

export default App;
