import React from 'react'
import Footer from '../../components/footer/Footer'
import css from "./priceoffer.module.css"

const PriceOffer = () => {
  return (
    <>
    <div className='flex flex-col items-center pb-36'>
        <div className='relative bg-black'>
            <img src='../img/wetransfer_01-pictures_2023-09-17_0843/01 pictures/top.jpg' alt='img' className={css.images}/>
            <div className='absolute -bottom-20 md:bottom-0 left-1/2 -translate-x-1/2 md:left-1/2 md:-translate-x-1/2 w-2/3 h-80 flex items-center'>
                <div className='flex flex-col gap-2 md:gap-6'>
                    <span className='text-white text-lg md:text-5xl font-bold leading-5'>Найдвартай тээвэрлэлт, Хурдан шуурхай үйлчилгээ</span>
                    <span className='text-white text-base md:text-3xl font-normal'>Скайпро Ложистикс ХХК</span>
                </div>
            </div>
        </div>
        <div className='w-2/3 '>
            <div className='w-full flex flex-col gap-4 md:gap-8 my-8'>
              <span className='text-black text-base md:text-5xl font-bold'>
                Үнийн санал авах форм
              </span>
              <div className='bg-[#DE5E62] h-1 w-32 md:w-52'></div>
            </div>

            <div className=' flex flex-row gap-10'>
                <div className=' w-full md:w-1/2 flex flex-col gap-4'>
                    <div className='grid grid-cols-2 gap-4'>
                        <input type='text' placeholder='Таны нэр' className='bg-white col-span-2 lg:col-span-1 text-xs h-[50px] w-full outline-none p-1 border-[1px] border-gray-300 drop-shadow-md'/>
                        <input type='text' placeholder='И-мэйл хаяг' className='bg-white col-span-2 lg:col-span-1 text-xs h-[50px] w-full outline-none p-1 border-[1px] border-gray-300 drop-shadow-md'/>                      
                    
                        <input type='text' placeholder='Утасны дугаар' className='bg-white w-full col-span-2 text-xs h-[50px] outline-none p-1 border-[1px] border-gray-300 drop-shadow-md'/>                  
                    
                        <input type='text' placeholder='Хаанаас' className='bg-white col-span-2 lg:col-span-1 text-xs w-full h-[50px] outline-none p-1 border-[1px] border-gray-300 drop-shadow-md'/>
                        <input type='text' placeholder='Хаашаа' className='bg-white col-span-2 lg:col-span-1 text-xs w-full h-[50px] outline-none p-1 border-[1px] border-gray-300 drop-shadow-md'/>                        
                    
                        <input type='text' placeholder='Ачааг хүлээн авах хаяг' className='bg-white col-span-2 lg:col-span-1 text-xs w-full h-[50px] outline-none p-1 border-[1px] border-gray-300 drop-shadow-md'/>
                        <input type='text' placeholder='Ачааны хэмжээ /Эзлэхүүн болон бохир жин/' className='bg-white col-span-2 lg:col-span-1 text-xs w-full h-[50px] outline-none p-1 border-[1px] border-gray-300 drop-shadow-md'/>                        
                    
                        <select className='bg-white w-full col-span-2 text-xs h-[50px] outline-none p-1 border-[1px] border-gray-300 drop-shadow-md'>
                            <option>Тээврийн төрөл сонгох</option>
                        </select>
                    
                        <input type='text' placeholder='Тэмдэглэл /Ачааны талаар дэлгэрэнгүй мэдээллийг бичнэ үү/' className='bg-white w-full col-span-2 text-xs h-36 outline-none p-1 border-[1px] border-gray-300 drop-shadow-md '/>
                    </div>
                    <div>
                        <button className='bg-[#DE5E62] w-40 h-14 outline-none text-xs font-semibold text-white'>Илгээх</button>
                    </div>
                </div>
                <div className={`hidden lg:block ${css.right}`}>
                    <div className={css.rightImg}>
                        <img src='../img/wetransfer_01-pictures_2023-09-17_0843/01 pictures/34.jpg' style={{ width: "100%", height: "100%", objectFit: "cover" }}/>
                        <div className={css.rectangle}></div>
                    </div>                    
                </div>
            </div>
            
        </div>
    </div>
        <Footer/>
    </>
  )
}

export default PriceOffer