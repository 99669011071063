import React from "react";
import css from "./buteegdehuun.module.css";

const Buteegdehuun = () => {
  const data = [
    {
      img: "https://i.imgur.com/Ipl5QBI.png",
      header: "Төмөр замын үйлчилгээ",
      text: "Бид ЕвроАзийг холбосон 100 орчим улс орноор дамжин өнгөрдөг төмөр замын өргөн сүлжээг ашиглан олон улсын тээвэрлэлтийг зохион байгуулдаг.",
    },
    {
      img: "https://i.imgur.com/QNUfQUL.png",
      header: "Агаарын тээвэр",
      text: "Монголоос болон Монгол руу шууд үйлдэж буй нислэгээр агаарын тээвэр зуучийн компаниудын сүлжээг ашиглан агаарын тээврийн үйлчилгээг үзүүлж байна.",
    },
    {
      img: "https://i.imgur.com/31bSR04.png",
      header: "Далайн тээвэр",
      text: "Далайд гарцгүй орнуудын хооронд далайн тээвэр болон бусад төрлийн тээврүүдийг хослуулан холимог тээвэр зохион байгуулах тээвэр зуучийн үйлчилгээ үзүүлдэг",
    },
    {
      img: "https://i.imgur.com/yS4y6Kc.png",
      header: "Авто тээвэр",
      text: "Авто замын тээвэр нь хаалганаас хаалганд хүртэл тээвэрлэлт хийх чадвартай,  өөрөөр  хэлбэл ачилт  буулгалт  хийх  тусгай  буудал, боомт шаардаад байдаггүй, нийлүүлэгчийн үйлдвэрийн агуулахаас хэрэглэгчид шууд хүргэж өгөх боломжтой байдгаараа тээврийн бусад хэлбэрээс давуу талтай.",
    },
    {
      img: "https://i.imgur.com/Xmc7Hzl.png",
      header: "Аюултай ачаа тээвэр",
      text: "Аюултай ачаа тээвэр нь тэсэрч дэлбэрэх , химийн хортой бодисууд орно. Стандарт нормын дагуу тээвэрлэж аюулыг багасгах гол нь байдаг. Бид Монгол улсад үйл ажиллагаа явуулж буй томоохон уул уурхайн хамтрагч байгууллагуудынхаа аюултай ачааг найдвартайгаар тээвэрлэсээр байна.",
    },
    {
      img: "https://i.imgur.com/mU2XNMz.png",
      header: "Түүвэр ачаа тээвэр",
      text: "Түүвэр ачаа нь таны ачааг бусад ачаатай нийлүүлэн боломжит үнээр, хугацаа хэмнэн тээвэрлэдгээрээ давуу талтай.  Та зөвхөн өөрийн ачааны хэмжээнд тохирсон тээврийн хөлс төлөх тул зардлын хувьд энэ төрлийн тээвэр нь хамгийн хямд байдаг.",
    },
  ];
  return (
    <div id="Бүтээгдэхүүн үйлчилгээ" style={{ backgroundColor: "white", zIndex: "-1" }}>
      <div className={css.container}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            width: "1280px",
            height: "90%",
          }}
        >
          <h1>Бүтээгдэхүүн үйлчилгээ</h1>
          <div className={css.redLine} />
          <div className={css.boxes}>
            {data.map((e) => (
              <div className={css.box}>
                <div className={css.imgWrapper}>
                  <img src={e.img} alt={e.img} />
                </div>
                <h3>{e.header}</h3>
                <p>{e.text}</p>
              </div>
            ))}
          </div>
        </div>
        <img
          className={css.backgroundImage}
          src="https://i.imgur.com/YeBYNm9.png"
          alt="map"
        />
      </div>
    </div>
  );
};

export default Buteegdehuun;
