import React from "react";

const Naidvartai = () => {
  return (
    <div class="flex sm:justify-center items-center w-screen h-[558px] bg-transparent px-4 sm:px-0">
      <div class="flex flex-col gap-10 sm:min-w-[1280px] ">
        <h1 class="text-3xl sm:text-5xl font-semibold text-white">
          Найдвартай тээвэрлэлт, <br /> Хурдан шуурхай үйлчилгээ
        </h1>
        <p class="text-2xl text-white">Скайпро Ложистикс ХХК</p>
      </div>
    </div>
  );
};

export default Naidvartai;
